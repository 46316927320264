/* src/App.css */

.app-container {
  padding: 20px;
  text-align: center;
}

.top-bar {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}

.carousel-container {
  background-color: #e9ecef;
  padding: 20px;
  border-radius: 5px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.thumbnail.active {
  border-color: #007bff;
}

/* Estilos para el logo */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Espacio opcional entre el logo y el carrusel */
}

.logo {
  max-width: 100px;
  height: auto;
}
/* Botón verde estilo Materialize */
.btn-materialize-green {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 0px;
  color: #fff;
  background-color: #43a047;
  transition: background-color 0.3s ease;
}

.btn-materialize-green:hover,
.btn-materialize-green:focus {
  background-color: #2e7d32;
}

/* Botón rojo estilo Materialize */
.btn-materialize-red {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 0px;
  color: #fff;
  background-color: #e53935;
  transition: background-color 0.3s ease;
}

.btn-materialize-red:hover,
.btn-materialize-red:focus {
  background-color: #c62828;
}
