.image-carousel-container {
    position: relative;
}

.thumbnail-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin: 5px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.thumbnail.active {
    border-color: #007bff;
}
